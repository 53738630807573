* {
  margin: 0;
  padding: 0;
  list-style: none;
}

html,
body {
  height: 100%;
  min-width: 960px;
}

#root {
  height: 100%;
}

.my-layout {
  height: 100%;
}

.ant-layout-header {
  padding: 0;
  height: 48px;
  line-height: 48px;
  background-color: #fff;
}
.ant-form-item {
  font-size: 16px;
  margin-bottom: 0;
  height: 68px;
}

.ant-layout .ant-layout-sider {
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.ant-menu-sub .ant-menu-inline {
  background-color: aqua !important;
}

.left-title {
  font-size: 16px;
  color: #313131;
  margin: 0px 68px 0px 24px;
  font-weight: 700;
}

.ant-header-right img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.ant-header-right {
  float: right;
  margin-right: 23px;
}

.ant-header-right a {
  margin-right: 40px;
}

.ant-header-right .sys-pepo {
  margin: 0px 8px;
}

.ant-header-right .setting {
  margin: 0px 16px;
}

.ant-menu-root span {
  color: #fff;
  font-size: 16px;
}

.ant-menu {
  background-color: transparent;
  /* background-color: red; */
}
.ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.ant-menu svg {
  fill: #fff;
  font-size: 16px;
}

.ant-menu-title-content a {
  color: #fff;
  font-size: 16px;
}

.ant-menu-submenu-title i {
  color: #fff;
  font-size: 16px;
}

.ant-menu-item::after {
  border-right: 0px;
}

.ant-layout-content {
  margin: 0px 24px;
}

.ant-modal-footer {
  text-align: center;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(255, 255, 255, 0.4);
}

.ant-menu-item-active {
  background: rgba(255, 255, 255, 0.4) !important;
  color: #fff !important;
}
